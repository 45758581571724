<div *ngIf="(uploadAvailable$ | async) === true" class="component-content">
  <div class="card-content" [formGroup]="fileForm">
    <div class="card-content-flex-block-one">
      <div class="upload-header">

        <div class="content-header-line">
          <div class="content-header-line-label"><span class="content-header-line-label-content">Von</span></div>
          <div class="content-header-line-input">
            <mat-form-field appearance="outline" class="select-from-client">
              <ng-container *ngIf="(userClients$ | async) as userClients">
                <mat-select formControlName="fromClientId" [disabled]="userClients.length === 1 || signMode"
                            placeholder="Auswahl vornehmen">
                  <mat-option *ngFor="let client of userClients" [value]="client.id">
                    {{ client.clientNumber + ': ' + client.name }}
                  </mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
          </div>
        </div>
        <div class="content-header-line">
          <div class="content-header-line-label"><span class="content-header-line-label-content">An</span></div>
          <div class="content-header-line-input">
            <mat-form-field appearance="outline" class="select-to-employee">

              <mat-select formControlName="toEmployeeId"
                          [disabled]="!fileForm.get('fromClientId')?.value || allEmployees.length === 1 || (loadingEmployees$ | async) === true || signMode">
                <ng-container *ngIf="!fileForm.get('fromClientId')?.value">
                  <mat-option disabled value="">
                    Bitte zuerst „Von“ auswählen
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="fileForm.get('fromClientId')?.value && allEmployees.length > 5">
                  <mat-option class="search-option">
                    <input matInput (input)="filterEmployees($event)" (click)="$event.stopPropagation()"
                           placeholder="Suche Mitarbeiter">
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="filteredEmployees.length > 0 && fileForm.get('fromClientId')?.value">
                  <mat-option *ngFor="let employee of filteredEmployees" [value]="employee.id">
                    {{ employee.display_name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="content-header-line-input-button-block">
            <div
              class="copy-to-me-toggle"
              [ngClass]="{'active': fileForm.get('copyToMe')?.value}"
              [ngStyle]="{ 'cursor': signMode ? 'default' : 'pointer' }"
              (click)="toggleCopyToMe()">
              <mat-icon>library_add_check</mat-icon>
              Kopie an mich
            </div>
          </div>
        </div>
        <div class="content-header-line">
          <div class="content-header-line-label-subject"><span class="content-header-line-label-content">Betreff</span>
          </div>
          <div class="content-header-line-input">
            <mat-form-field appearance="outline" class="input-subject">
              <input matInput formControlName="subject"
                     [readonly]="signMode"/>
            </mat-form-field>
          </div>

          <div class="content-header-line-input-button-block">
            <button mat-raised-button color="primary"
                    class="mat-raised-button-primary content-header-line-input-button button-send"
                    [disabled]="fileForm.invalid || (loadingSubmit$ | async) === true" (click)="submitNewFile()">
              <mat-icon>send</mat-icon>
              Senden
            </button>
          </div>
        </div>

        <div
          class="copy-to-me-toggle-mobile"
          [ngClass]="{'active': fileForm.get('copyToMe')?.value}"
          (click)="toggleCopyToMe()">
          <mat-icon>library_add_check</mat-icon>
          Kopie an mich
        </div>


        <button mat-raised-button color="primary"
                class="mat-raised-button-primary button-send-mobile"
                [disabled]="fileForm.invalid || (loadingSubmit$ | async) === true" (click)="submitNewFile()">
          <mat-icon>send</mat-icon>
          Senden
        </button>

      </div>
      <div class="block-files-and-controls">
        <!-- Bereich für den "Anhänge hinzufügen"-Button -->
        <div class="file-wrapper-button">
          <div *ngIf="filesToUpload.length > 0 && modeFiles === 'HIDE'"
               (click)="changeModeFiles('SHOW')">
            <mat-icon class="icon-arrow-files">keyboard_arrow_down</mat-icon>
          </div>
          <div *ngIf="filesToUpload.length > 0 && modeFiles === 'SHOW'"
               (click)="changeModeFiles('HIDE')">
            <mat-icon class="icon-arrow-files">keyboard_arrow_up</mat-icon>
          </div>
          <button
            [disabled]="signMode"
            mat-raised-button
            color="primary"
            class="mat-raised-button-primary file-button"
            (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
            Anhänge hinzufügen
          </button>
          <input #fileInput type="file" (change)="onFileSelected($event)" hidden multiple>
        </div>

        <!-- Bereich für "Abbrechen"-Button und Checkbox -->
        <div class="cancel-and-checkbox">
          <button
            mat-button
            color="primary"
            class="mat-button-primary button-cancel"
            (click)="cancelUpload()">Abbrechen
          </button>
          <mat-checkbox *ngIf="!signMode"
                        class="checkbox-answerRequested"
                        formControlName="answerRequested"
                        (change)="syncCheckboxes()">Rückmeldung erwünscht
          </mat-checkbox>
        </div>
      </div>

      <div class="block-files-to-upload" *ngIf="filesToUpload.length > 0 && modeFiles === 'SHOW'">
        <div *ngFor="let file of filesToUpload" class="row-files">
          <div class="block-file">
            <mat-icon class="icon-delete-file"
                      *ngIf="!signMode"
                      (click)="deleteFromUploadList(file)">close
            </mat-icon>
            <div class="file-icon">
              <ng-container *ngIf="file.type === 'pdf'">
                <img class="png-file-icon-size" src="assets/icons/pdf.png" alt="PDF Icon"/>
              </ng-container>
              <ng-container *ngIf="file.type === 'doc' || file.type === 'docx'">
                <img class="png-file-icon-size" src="assets/icons/word.png" alt="Word Icon"/>
              </ng-container>
              <ng-container *ngIf="file.type === 'xls' || file.type === 'xlsx' || file.type === 'csv'">
                <img class="png-file-icon-size" src="assets/icons/excel.png" alt="Excel Icon"/>
              </ng-container>
              <ng-container
                *ngIf="file.type !== 'pdf' && file.type !== 'doc' && file.type !== 'docx' && file.type !== 'xls' && file.type !== 'xlsx' && file.type !== 'csv'">
                <img class="png-file-icon-size" src="assets/icons/file.png" alt="Default File Icon"/>
              </ng-container>
            </div>
            <div class="file-name">{{ file.name }}</div>
          </div>
        </div>
      </div>

      <div class="block-note">
        <mat-form-field appearance="outline" class="form-field-textarea"
                        [ngClass]="{'drag-over': isDragging && !signMode}">
          <mat-label>Nachricht an Kanzlei</mat-label>
          <textarea
            matInput
            formControlName="note"
            [readonly]="signMode"
            class="textarea-note"

            placeholder="Geben Sie Ihre Beschreibung ein oder ziehen Sie Dateien hierher"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
            (dragenter)="onDragEnter($event)"
            (dragleave)="onDragLeave($event)">
            </textarea>
        </mat-form-field>
      </div>
    </div>
      <div class="block-files-and-controls">
        <!-- Bereich für den "Anhänge hinzufügen"-Button -->
        <div class="file-wrapper-button">
          <button
            [disabled]="signMode"
            mat-raised-button
            color="primary"
            class="mat-raised-button-primary file-button"
            (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
            Anhänge hinzufügen
          </button>
          <input #fileInput type="file" (change)="onFileSelected($event)" hidden multiple>
        </div>

        <!-- Bereich für "Abbrechen"-Button und Checkbox -->
        <div class="cancel-and-checkbox">
          <button
            mat-button
            color="primary"
            class="mat-button-primary button-cancel"
            (click)="cancelUpload()">Abbrechen
          </button>
          <mat-checkbox *ngIf="!signMode"
                        class="checkbox-answerRequested"
                        formControlName="answerRequested"
                        (change)="syncCheckboxes()">Rückmeldung erwünscht
          </mat-checkbox>
        </div>
      </div>
    </div>
</div>
<div *ngIf="(loadingSubmit$ | async)" class="loading-overlay">
  <mat-spinner></mat-spinner>
</div>
