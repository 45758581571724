import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app.routing";
import { CreateInstanceComponent } from "./components/admin/create-instance/create-instance.component";
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { REQUEST_ENVIRONMENT } from "./services/request.token";
import { environment } from "../environments/environment";
import { NavbarComponent } from './components/navbar/navbar.component';
import { SessionManagerService } from "./services/session-manager.service";
import { StartPageComponent } from './components/start-page/start-page.component';
import { OrdersPageComponent } from './components/orders-page/orders-page.component';
import { BaseDataPageComponent } from './components/base-data-page/base-data-page.component';
import { AuthGuardService } from "./services/auth-guard.service";
import { RequestHTTPProviders } from "./services/request-interceptor.service";
import { MatListModule } from "@angular/material/list";
import { NotifierModule } from "angular-notifier";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { ClientsComponent } from './components/admin-dashboard/clients/clients.component';
import { MatSelectModule } from "@angular/material/select";
import { UsersComponent } from './components/admin-dashboard/users/users.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  UserClientsModalComponent
} from './components/admin-dashboard/userClients/user-clients-modal/user-clients-modal.component';
import { DocumentFilterService } from "./services/document-filter.service";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DmsSettingsComponent } from './components/admin-dashboard/dms-settings/dms-settings.component';
import { MatTabsModule } from "@angular/material/tabs";
import { GeneralSettingsComponent } from './components/admin-dashboard/general-settings/general-settings.component';
import {
  SyncMailsPeriodsAssessmentsSettingsComponent
} from './components/admin-dashboard/sync-mails-periods-assessments-settings/sync-mails-periods-assessments-settings.component';
import { ColorPickerModule } from 'ngx-color-picker';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UserApprovalComponent } from './components/user-approval/user-approval.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { PasswordAssignmentComponent } from './components/password-assignment/password-assignment.component';
import {
  ModalConfirmDownloadDocumentsComponent
} from './components/modal-confirm-download-documents/modal-confirm-download-documents.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {
  DocumentsListUngroupedComponent
} from './components/documents-page/documents-list-ungrouped/documents-list-ungrouped.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BaseDataUserPageComponent } from './components/base-data-user-page/base-data-user-page.component';
import { BaseDataClientsPageComponent } from './components/base-data-clients-page/base-data-clients-page.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UploadDocumentsComponent } from "./components/documents-page/upload-documents/upload-documents.component";
import { DocumentsListComponent } from './components/documents-page/documents-list/documents-list.component';
import {
  ModalConfirmDeleteUserComponent
} from "./components/admin-dashboard/users/modal-confirm-delete-user/modal-confirm-delete-user.component";
import {
  ModalConfirmDeleteComponent
} from "./components/user-approval/modal-confirm-delete/modal-confirm-delete.component";
import {
  ModalDatevconnectionProblemsComponent
} from './components/modal-datevconnection-problems/modal-datevconnection-problems.component';
import { OutboxDetailsComponent } from './components/documents-page/outbox-details/outbox-details.component';
import { TruncatePipe } from './services/truncate.pipe';
import {
  ModalTaskApprovalComponent
} from './components/documents-page/modal-task-approval/modal-task-approval.component';
import { RemoveBracketsPipe } from './services/remove-brackets.pipe';
import { ColorsService } from "./services/colors.service";
import { LoginService } from "./services/login.service";
import { RequestManagerService } from "./services/request-manager.service";


// Register locale data
registerLocaleData(localeDe);

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'DD.MM.YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    CreateInstanceComponent,
    LoginComponent,
    NavbarComponent,
    StartPageComponent,
    OrdersPageComponent,
    BaseDataPageComponent,
    AdminDashboardComponent,
    ClientsComponent,
    UsersComponent,
    UserClientsModalComponent,
    NotFoundComponent,
    DmsSettingsComponent,
    GeneralSettingsComponent,
    SyncMailsPeriodsAssessmentsSettingsComponent,
    UserApprovalComponent,
    PasswordAssignmentComponent,
    ModalConfirmDownloadDocumentsComponent,
    DocumentsListUngroupedComponent,
    BaseDataUserPageComponent,
    BaseDataClientsPageComponent,
    UploadDocumentsComponent,
    DocumentsListComponent,
    ModalConfirmDeleteUserComponent,
    ModalConfirmDeleteComponent,
    ModalDatevconnectionProblemsComponent,
    OutboxDetailsComponent,
    TruncatePipe,
    ModalTaskApprovalComponent,
    RemoveBracketsPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    NgxFileDropModule,
    MatTabsModule,
    ColorPickerModule,
    MatProgressBarModule,
    MatDatepickerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 20,
        },
        vertical: {
          position: 'top',
          distance: 120,
        },
      },
      behaviour: {
        autoHide: 3000,
      },
    }),
    MatTooltipModule,
    MatSidenavModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    DocumentFilterService,
    SessionManagerService,
    LoginService,
    ColorsService,
    RequestManagerService,
    RequestHTTPProviders,
    // Let angular know which value to use when injecting the token at runtime.
    {
      provide: REQUEST_ENVIRONMENT, useValue: {
        baseURL: environment.baseURL
      },
    },
    {provide: LOCALE_ID, useValue: 'de-AT'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'contract_edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/contract_edit.svg')
    );
  }
}
