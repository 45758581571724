<div class="block-outboxes">

  <!-- header if width of screen is lower than 28rem-->
  <div class="mat-card-header-end-block-mobile" *ngIf="mode === 'list'">
    <div class="row-1">
      <mat-form-field [ngClass]="{
    'select-client-dataProtectionMode-inactive': settings && settings.dataProtectionModeActive === '0',
    'select-client-dataProtectionMode-active': !settings || (settings && settings.dataProtectionModeActive === '1')
  }">
        <mat-select *ngIf="(userClients$ | async) as clients"

                    (ngModelChange)="onClientSelectedChange($event)"
                    [(ngModel)]="clientSelected">
          <mat-option [value]="'all'">
            Alle
          </mat-option>
          <mat-option *ngFor="let client of clients" [value]="client.clientNumber">
            {{client.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="block-button-filter">
        <mat-icon class="icon-filter" [mat-menu-trigger-for]="filterMenu">filter_list</mat-icon>
      </div>
    </div>

    <div class="row-2" *ngIf="settings && settings.dataProtectionModeActive === '0'">
      <mat-form-field class="searchInput">
        <mat-label>Suche...</mat-label>
        <input matInput [formControl]="searchControl"/>
      </mat-form-field>
    </div>
  </div>

  <!-- header if width of screen is greater 28rem-->
  <div class="mat-card-header-end-block-desktop" *ngIf="mode === 'list'">
    <mat-form-field class="input-select-client" [ngClass]="{
    'select-client-dataProtectionMode-inactive': settings && settings.dataProtectionModeActive === '0',
    'select-client-dataProtectionMode-active': !settings || (settings && settings.dataProtectionModeActive === '1')
  }">
      <mat-select *ngIf="(userClients$ | async) as clients"
                  (ngModelChange)="onClientSelectedChange($event)"
                  [(ngModel)]="clientSelected"
      >
        <mat-option [value]="'all'">
          Alle
        </mat-option>
        <mat-option *ngFor="let client of clients" [value]="client.clientNumber">
          {{client.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="settings && settings.dataProtectionModeActive === '0'"
         class="searchInput"
    >
      <mat-form-field class="searchInput">
        <mat-label>Suche...</mat-label>
        <input matInput [formControl]="searchControl"/>
      </mat-form-field>
    </div>

    <div class="block-button-filter">
      <mat-icon class="icon-filter" matTooltip="Filter" aria-hidden="false" role="img"
                #menuTrigger="matMenuTrigger"
                [mat-menu-trigger-for]="filterMenu">filter_list
      </mat-icon>
    </div>
  </div>


  <div class="filter-placeholder mat-card-header-end-block-desktop-send" *ngIf="mode === 'send'">
    <mat-icon class="mat-icon-close-send"
              (click)="handleModeChange('list')">close
    </mat-icon>
  </div>


  <mat-menu #filterMenu class="filterMenu">
    <div class="menu-content">
      <h3 (click)="$event.stopPropagation();">Filter</h3>
      <div class="filter-select-downloaded">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [checked]="isDownloadedFilter === 'notDownloaded'"
          (change)="changeIsDownloadedFilter($event)">Nur ungelesene Nachrichten
        </mat-checkbox>
      </div>

      <div class="filter-select-downloaded">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [checked]="taskUnDoneFilter === '1'"
          (change)="changeTaskUndoneFilter($event)">Nur aktive Aufgaben
        </mat-checkbox>
      </div>

      <div class="block-date-range" [formGroup]="dateRangeFormGroup">
        <mat-form-field>
          <mat-label>Wähle einen Zeitraum</mat-label>
          <mat-date-range-input
            [rangePicker]="picker"
            (change)="dateRangeChanged()">
            <input matStartDate placeholder="Von" formControlName="start"> <input
            matEndDate placeholder="Bis" formControlName="end">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="mat-menu-footer">
        <button mat-button color="primary" (click)="resetFilter()">Zurücksetzen</button>
        <button mat-raised-button color="primary" (click)="applyFilter()">Anwenden</button>
      </div>
    </div>
  </mat-menu>
</div>

<mat-card class="card-documents">
  <mat-card-content>
    <app-documents-list-ungrouped
      [mode]="mode"
      [outboxes]="outboxes"
      [loading]="loading$ | async"
      [clientSelectedAll]="clientSelected === 'all'"
      [settings]="settings"
      (openFilter)="openFilter()"
      (reload)="reloadDocuments(true)"
      (modeChange)="handleModeChange($event)">
      <a id="block-service-contact"
         href="{{contactLink}}" target="_blank">
        <div class="block-service-contacts-icons">
          <img class="block-service-contacts-icon" src="assets/icons/Icon_Service.svg">
          <img class="block-service-contacts-icon" src="assets/icons/Icon_Contact.svg">
        </div>
        <button mat-raised-button class="button-primary" color="primary">
          Service & Kontakt
        </button>
      </a>
      <div class="pagination-buttons-and-select">
        <mat-form-field id="select-max-number-outboxes">
          <mat-label>Max. <span id="text-number-outboxes-hide-1">Nachrichten</span> <span
            id="text-number-outboxes-hide-2">&nbsp;pro Seite</span></mat-label>
          <mat-select [(ngModel)]="limit" (ngModelChange)="offset=0; reloadDocuments(true)">
            <mat-option *ngFor="let limitOption of limitOptions" [value]="limitOption">
              {{limitOption}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="pagination-buttons">
          <button
            mat-button color="secondary"
            class="secondary-color pagination-button"
            id="button-pagination-previous"
            [disabled]="previousPageButtonIsDisabled()"
            (click)="getPreviousPage()">
            <mat-icon color="secondary">chevron_left</mat-icon>
            <span class="text-button-pagination">Vorherige</span>
          </button>
          <button
            mat-button
            color="secondary"
            class="secondary-color pagination-button"
            [disabled]="nextPageDisabled"
            (click)="getNextPage()"><span class="text-button-pagination">Nächste</span>
            <mat-icon color="secondary">chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </app-documents-list-ungrouped>


  </mat-card-content>
</mat-card>
