<div class="main-grid-list-detail main-grid-list-detail-height-auto" *ngIf="checkTaskStatus()">
  <!-- Versteckte Checkbox zum Umschalten -->
  <input type="checkbox" id="toggle-checkbox-details" class="toggle-checkbox-details"/>
  <input type="checkbox" id="toggle-checkbox-send" class="toggle-checkbox-send"/>

  <div class="block-outboxes-list-wrapper">
    <button mat-raised-button class="button-primary block-send-documents" color="primary" *ngIf="mode === 'list'" (click)="setModeToSend()">
      <mat-icon>outgoing_mail</mat-icon>
      <span>Dokumente / Nachricht senden</span>
    </button>
    <div class="block-outboxes-list">
      <div class="block-spinner" *ngIf="loading === true">
        <mat-spinner class="loading-spinner" [diameter]="35"></mat-spinner>
      </div>

      <ng-container *ngIf="outboxes && outboxes.length > 0">
        <div *ngFor="let outbox of outboxes; let i = index; let last = last" class="">
          <label for="toggle-checkbox-details" class="outbox-element"
                 [ngStyle]="{'height': clientSelectedAll ? '6rem' : '5rem'}"
                 (click)="changeSelectedOutbox(outbox)"
                 [ngClass]="{'outbox-not-downloaded': !allDocumentsOfOutboxAreDownloaded(outbox.documents)}">
            <div class="block-outbox-element-mat-icon-not-downloaded">
              <mat-icon *ngIf="!allDocumentsOfOutboxAreDownloaded(outbox.documents)" class="mat-icon-circle">circle
              </mat-icon>
              <mat-spinner *ngIf="isDownloadingOutbox(outbox)" class="document-spinner" [diameter]="20"></mat-spinner>
            </div>
            <div class="outbox-element-information">
              <div *ngIf="clientSelectedAll"><b>Mandant: {{outbox.outboxDetails.receiver_name}}</b></div>
              <div
                [ngStyle]="{'font-weight': clientSelectedAll && allDocumentsOfOutboxAreDownloaded(outbox.documents) ? 'normal' : 'bold'}"
                class="outbox-element-middle-line">
                <div class="outbox-element-icon-text">
                  <mat-icon>person</mat-icon>
                  <span
                    *ngIf="this.displayCommittingEmployeeAsSender === '1'">Von: {{outbox.outboxDetails.committed_by_employee_name}}</span>
                  <span
                    *ngIf="this.displayCommittingEmployeeAsSender !== '1'">Von: {{outbox.outboxDetails.creating_employee_name}}</span>
                </div>
                <div>{{outbox.outboxDetails.commit_date | date:'dd.MM.yyyy'}}</div>
              </div>
              <div class="outbox-element-last-line">
                <div class="subject outbox-element-icon-text">
                  <mat-icon>subject</mat-icon>
                  <mat-icon *ngIf="allDocumentsAreOnlyDocuments(outbox.documents)">attach_file</mat-icon>
                  <span id="subject-truncate-50">{{outbox.outboxDetails.subject | truncate:50}}</span>
                  <span id="subject-truncate-30">{{outbox.outboxDetails.subject | truncate:30}}</span>
                  <span id="subject-truncate-20">{{outbox.outboxDetails.subject | truncate:20}}</span>
                </div>
                <ng-container *ngIf="openTaskStatusId && doneTaskStatusId">

                  <div *ngIf="documentHasUndoneTasks(outbox.documents)"
                       (click)="$event.stopPropagation();approveTasks(outbox.outboxDetails.id)">
                    <mat-icon class="icon_task bold-icon">task_alt</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
          </label>
        </div>
      </ng-container>
      <div *ngIf="(!outboxes || outboxes.length === 0) && loading === false" class="block-no-documents">
        <a mat-button (click)="resetFilter()" class="no-padding">
          Keine Nachrichten<br> Filter anpassen um Nachrichten anzuzeigen
        </a>
      </div>
    </div>

    <ng-content></ng-content>
  </div>


  <!-- Anzeige der Komponente `upload-documents` im `send`-Modus -->
  <div class="app-upload-documents" *ngIf="mode === 'send'">
    <label for="toggle-checkbox-send" class="back-button"
           (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </label>
    <app-upload-documents
      (close)="closeUploadDocuments()"></app-upload-documents>
  </div>

  <div class="app-outbox-details" *ngIf="mode === 'list'">
    <label for="toggle-checkbox-details" class="back-button"
    >
      <mat-icon>arrow_back</mat-icon>
    </label>
    <app-outbox-details [outboxes]="outboxes" [outboxDetails]="selectedOutbox"
                        [displayCommittingEmployeeAsSender]="displayCommittingEmployeeAsSender"
                        (modeChange)="handleModeChange($event)" (openFilter)="resetFilter()">
    </app-outbox-details>
  </div>


</div>
