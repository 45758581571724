<div class="block-component-wrapper-no-outbox-selected" *ngIf="outbox === null">
  <div class="block-no-outbox-selected">
    <mat-icon id="mail-icon-no-outbox-selected">mail_outline</mat-icon>
    <span *ngIf="outboxes && outboxes.length > 0">Wählen Sie eine Nachricht aus</span>
    <a mat-button *ngIf="outboxes && outboxes.length === 0" (click)="resetFilter()">
      Filter anpassen um Nachrichten anzuzeigen
    </a>
  </div>
</div>
<div *ngIf="outbox" class="block-component-wrapper-outbox-selected">
  <div class="outbox-details-header-wrapper">
    <div class="outbox-details-headerline-action-buttons">
      <button mat-raised-button
              class="button-primary no-padding"
              color="primary"
              (click)="answerOutbox()">
        <div class="flex flex-middle">
          <mat-icon>reply</mat-icon>
          <span class="button-space">Antworten</span>
        </div>
      </button>
      <button *ngIf="!allDocumentsAreOnlyPhoneNotes()"
              mat-raised-button
              class="button-primary no-padding"
              color="primary"
              (click)="answerOutbox(true)">
        <div class="flex flex-middle">
          <mat-icon svgIcon="contract_edit" color="primary"></mat-icon>
          <span class="button-space">Unterschreiben</span>
        </div>
      </button>
    </div>
    <hr class="custom-line">
    <div class="flex flex-between flex-middle margin-vertical padding">
      <span class="outbox-details-headerline-subject">{{ outbox.outboxDetails.subject }}</span>
      <span
        class="outbox-details-headerline-pre-subject">{{ outbox.outboxDetails.commit_date | date:'dd.MM.yyyy - hh:mm' }}</span>
    </div>
    <div class="outbox-details-header">
      <div class="outbox-details-sender-wrapper">
        <span><b>Von:</b></span>
        <div class="outbox-details-sender">
          <span *ngIf="this.displayCommittingEmployeeAsSender ==='1'">
            {{ outbox.outboxDetails.committed_by_employee_name }}
          </span>
          <span *ngIf="this.displayCommittingEmployeeAsSender !=='1'">
            {{ outbox.outboxDetails.creating_employee_name }}
          </span>
          <mat-icon>person</mat-icon>
        </div>
      </div>
    </div>
    <hr class="custom-line-2">
    <div class="outbox-details-header-files">
      <div class="outbox-details-header-file-details">
        <div>
          <div *ngIf="outbox.documents.length > 0 && modeFiles === 'HIDE'"
               (click)="changeModeFiles('SHOW')">
            <mat-icon class="icon-arrow-files">keyboard_arrow_down</mat-icon>
          </div>
          <div *ngIf="outbox.documents.length > 0 && modeFiles === 'SHOW'"
               (click)="changeModeFiles('HIDE')">
            <mat-icon class="icon-arrow-files">keyboard_arrow_up</mat-icon>
          </div>
        </div>
        {{ outbox.documents.length }}
        <span *ngIf="outbox.documents.length === 1">&nbsp;{{ "Anhang" }}</span>
        <span *ngIf="outbox.documents.length > 1">&nbsp;{{ "Anhänge" }}</span>
        <mat-icon>attach_file</mat-icon>
        <!--<span *ngIf="outbox.documents.length === 1" class="truncate">
          {{ getDocumentTitle(outbox.documents[0].description, outbox.documents[0].extension) }}
        </span>
        <span *ngIf="outbox.documents.length > 1">
          {{ getDocumentTitle(outbox.documents[0].description, outbox.documents[0].extension) }} u.a.
        </span>-->
      </div>

      <ng-container *ngIf="!downloadingAllDocuments; else spinnerTemplate">
        <button mat-raised-button (click)="downloadDocuments()"
                color="primary"
                class="button-primary">
          <mat-icon class="outbox-details-header-files-icon-download">download</mat-icon>
          Alle herunterladen
        </button>
      </ng-container>
      <ng-template #spinnerTemplate>
        <mat-spinner [diameter]="24"></mat-spinner>
      </ng-template>
    </div>

    <div class="block-files-to-upload" *ngIf="outbox.documents.length > 0 && modeFiles === 'SHOW'">
      <div *ngFor="let file of outbox.documents" class="row-files">
        <div class="block-file" (click)="downloadDocument(file)">
          <ng-container *ngIf="!downloadingDocuments[file.id]; else spinner">
            <mat-icon class="icon-download-file">download</mat-icon>
          </ng-container>
          <ng-template #spinner>
            <mat-spinner class="spinner-pos" [diameter]="24"></mat-spinner>
          </ng-template>
          <div class="file-icon">
            <ng-container *ngIf="file.extension === 'pdf'">
              <img class="png-file-icon-size" src="assets/icons/pdf.png" alt="PDF Icon"/>
            </ng-container>
            <ng-container *ngIf="file.extension === 'doc' || file.extension === 'docx'">
              <img class="png-file-icon-size" src="assets/icons/word.png" alt="Word Icon"/>
            </ng-container>
            <ng-container *ngIf="file.extension === 'xls' || file.extension === 'xlsx' || file.extension === 'csv'">
              <img class="png-file-icon-size" src="assets/icons/excel.png" alt="Excel Icon"/>
            </ng-container>
            <ng-container
              *ngIf="file.extension !== 'pdf' && file.extension !== 'doc' && file.extension !== 'docx' && file.extension !== 'xls' && file.extension !== 'xlsx' && file.extension !== 'csv'">
              <img class="png-file-icon-size" src="assets/icons/file.png" alt="Default File Icon"/>
            </ng-container>
          </div>
          <div class="file-name">{{ file.description }}</div>
        </div>
      </div>
    </div>

  </div>
  <hr class="custom-line-2">
  <div class="padding">
    <ng-container *ngFor="let document of outbox.documents">
      <ng-container *ngIf="checkAndLoadDocumentNote(document)">
        <mat-card class="mat-card-document">
          <ng-container *ngIf="documentIsPhoneNote(document) else documentIsNoPhoneNote">
            <div class="mat-card-document-flex-left">
              <div class="disabled"
                   matTooltip="Keine Datei zum Download verknüpft"
                   matTooltipPosition="right">
                <mat-icon class="disabled">description</mat-icon>
              </div>
              <div class="disabled"
                   matTooltip="Keine Datei zum Download verknüpft"
                   matTooltipPosition="right">
            <span class="document-title">
              {{ "zu: " + getDocumentTitle(document.description, document.extension) | truncate: 75 }}
            </span>
              </div>
            </div>
          </ng-container>

          <ng-template #documentIsNoPhoneNote>
            <div class="mat-card-document-flex-left cursor-pointer">
              <mat-icon>description</mat-icon>
              <span *ngIf="outbox.documents.length > 1" class="document-title">
            {{ "zu: " + getDocumentTitle(document.description, document.extension) | truncate: 75 }}
          </span>
            </div>
          </ng-template>

          <!-- Notiz anzeigen -->
          <div class="document-note" *ngIf="checkAndLoadDocumentNote(document)">
            <i>{{ document.note.text | removeBrackets }}</i>
          </div>

          <!-- Ladezustand für Notizen -->
          <div class="document-note-loading" *ngIf="loadingNotes[document.id]">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!checkAndLoadDocumentNote(outbox.documents[0])">
      <div class="margin-top">
        <i>Bitte beachten Sie den Anhang</i>
      </div>
    </ng-container>

  </div>
</div>
